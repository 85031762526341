import { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import {
  Layout,
  Select,
  SelectItem,
  Text,
  useTheme,
} from '@ui-kitten/components';
import dayjs from 'dayjs';
import Collapsible from 'react-native-collapsible';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';
import lodashFind from 'lodash/find';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';
import PopupModal from '../Web/PopupModal';

import { useGetOrderDatesQuery } from '../../RTK/api/store';

import { SchedulePickerProps } from './types';
import { useSelector } from 'react-redux';
import { storeCheckoutSelector } from '../../RTK/checkout/selectors';
import { checkout } from '../../RTK/defaultValues';
import { useRoute } from '@react-navigation/native';
import routeList from '../../Routes/list';
import useSchedulePicker from './index.controller';
import {
  tagsFilterSelector,
  whenFilterSelector,
} from '../../RTK/filter/selectors';
import { AvailableOrderDates, StoreAvailableOrderDates } from '../../Types';

const {
  DATE_ONLY_DISPLAY_FORMAT,
  TIME_ONLY_DISPLAY_CAPITAL_FORMAT,
  DBDATE_FORMAT,
  ORDER_TYPES,
} = constants;

// This component is same on index.web.js BUT it's not generating the store hours
const SchedulePicker = ({
  storeId, // if this is pass, scheduleList is not needed
  scheduleList = [], // if this is pass, storeId is not needed
  scheduleTitle = 'Select Schedule',
  show: isOpen,
  onConfirm,
  onCancel,
}: SchedulePickerProps) => {
  const storeCheckoutData = useSelector((state) =>
    //@ts-ignore
    storeCheckoutSelector(state, storeId)
  );
  const filterHomeTags = useSelector(tagsFilterSelector);
  const whenFilter = useSelector(whenFilterSelector);

  const route = useRoute();
  const {
    data: orderDatesData,
    isFetching: isFetchingOrderDates,
    isSuccess: isSuccessOrderDates,
  } = useGetOrderDatesQuery({ payload: storeId }, { skip: !Boolean(storeId) });

  const { generateDateOptions } = useSchedulePicker();
  const [isAsap, setIsAsap] = useState(lodashIsEmpty(whenFilter));
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedHours, setSelectedHours] = useState<any>();

  const dates: StoreAvailableOrderDates[] = Boolean(storeId)
    ? isSuccessOrderDates
      ? orderDatesData
      : []
    : scheduleList.length > 0 || generateDateOptions();

  const selectedDayTimeSlot: AvailableOrderDates = lodashFind(dates, {
    value: dayjs(whenFilter?.value).format(DBDATE_FORMAT),
  });

  const hours = lodashFilter(
    selectedDate?.time_options || selectedDayTimeSlot?.time_options,
    (e) => e?.value !== 'asap_order_time'
  );

  const orderType =
    route.name === routeList.HOME_TAB
      ? filterHomeTags.filter((item) => item.paramName === 'order_type') // Filter for home page
      : storeCheckoutData[checkout.keys.ORDER_TYPE]; // for store page

  const isPickup =
    route.name === routeList.HOME_TAB
      ? orderType[0]?.values[0]?.value?.includes(ORDER_TYPES.PICKUP)
      : orderType?.value?.includes(ORDER_TYPES.PICKUP);

  const label = `${isPickup ? 'Pickup' : 'Delivery'}`;

  const orderTypeLabel =
    route.name === routeList.HOME_TAB
      ? orderType.length > 0
        ? label
        : 'Order'
      : label;

  const canAsap =
    !Boolean(storeId) ||
    dates?.[0]?.time_options?.[0].value === 'asap_order_time' ||
    false;

  useEffect(() => {
    if (!lodashIsEmpty(whenFilter?.value)) {
      setSelectedDate({
        iso: whenFilter?.value,
      });
      setSelectedHours({
        start: whenFilter?.value,
        end: `${dayjs(whenFilter?.time, 'hh:mm').add(30, 'minutes').format()}`,
      });
      return;
    }
    if (!canAsap) setIsAsap(canAsap);
  }, [isAsap, canAsap]);

  const _formatDate = (data) => {
    if (lodashIsEmpty(data)) return undefined;
    const { iso } = data;
    return dayjs(iso).format(DATE_ONLY_DISPLAY_FORMAT);
  };

  const _formatTime = (data) => {
    if (lodashIsEmpty(data)) return undefined;
    const { start, end } = data;
    return `${dayjs(start).format(TIME_ONLY_DISPLAY_CAPITAL_FORMAT)} - ${dayjs(
      end
    ).format(TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`;
  };

  const _onClose = () => {
    setSelectedDate(undefined);
    setSelectedHours(undefined);
    onCancel?.();
  };
  const toggleOrderTime = () => {
    setIsAsap(!isAsap);
    setSelectedDate(undefined);
    setSelectedHours(undefined);
  };

  const _onProceed = () => {
    if (isAsap) {
      onConfirm?.(null);
      _onClose();
      return;
    }
    return onConfirm?.(selectedHours.start);
  };

  const store_theme = useTheme();
  const enabledStyle = {
    ...styles.enableBtn,
    backgroundColor: store_theme['button_color'] || ThemeColor.green,
    borderColor: store_theme['button_color'] || ThemeColor.green,
  };

  return (
    <PopupModal
      isOpen={isOpen}
      onCloseButtonClick={_onClose}
      title={scheduleTitle}
      hasHeaderLine
      mainContentWrapperStyle={{ width: 550 }}
    >
      <Layout
        style={[ThemeStyle.spacingTopSmall, ThemeStyle.spacingBottomSmall]}
      >
        {canAsap && (
          <Layout style={ThemeStyle.spacingBottomMedium}>
            <Layout style={styles.container}>
              <Layout style={styles.layout} level="1">
                <Button
                  style={isAsap ? enabledStyle : styles.disableBtn}
                  onPress={toggleOrderTime}
                  plain
                >
                  <Layout style={styles.innerBtn}>
                    <Text category="p2" status={isAsap && 'control'}>
                      ASAP
                    </Text>
                  </Layout>
                </Button>
              </Layout>

              <Layout style={styles.layout} level="1">
                <Button
                  plain
                  style={!isAsap ? enabledStyle : styles.disableBtn}
                  onPress={toggleOrderTime}
                >
                  <Layout style={styles.innerBtn}>
                    <Text category="p2" status={!isAsap && 'control'}>
                      Pre-Order
                    </Text>
                  </Layout>
                </Button>
              </Layout>
            </Layout>
          </Layout>
        )}

        <Collapsible collapsed={isAsap}>
          <Layout
            style={[ThemeStyle.spacingTopSmall, ThemeStyle.spacingBottomMedium]}
          >
            <Layout style={styles.container}>
              <Layout style={styles.layout} level="1">
                <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                  <Text category="label">{orderTypeLabel} Date</Text>
                </Layout>
                <Select
                  accessoryRight={
                    isFetchingOrderDates && (
                      <ActivityIndicator
                        color={store_theme['color-success-500']}
                      />
                    )
                  }
                  placeholder={
                    isFetchingOrderDates ? 'Loading...' : 'Select Dates'
                  }
                  onSelect={({ row }: any) => {
                    setSelectedDate(dates[row]);
                  }}
                  value={
                    !lodashIsEmpty(selectedDate)
                      ? _formatDate(selectedDate)
                      : !lodashIsEmpty(whenFilter?.value)
                      ? dayjs(whenFilter?.value).format('dddd, MMM DD')
                      : ''
                  }
                  size="large"
                >
                  {dates.map((data, i) => (
                    <SelectItem
                      key={i}
                      title={_formatDate(data)}
                      style={{ fontSize: 14 }}
                    />
                  ))}
                </Select>
              </Layout>

              <Layout style={styles.layout} level="1">
                <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                  <Text category="label">
                    {orderTypeLabel === 'Delivery'
                      ? 'Dispatch'
                      : orderTypeLabel}{' '}
                    Time
                  </Text>
                </Layout>
                <Select
                  accessoryRight={
                    isFetchingOrderDates && (
                      <ActivityIndicator
                        color={store_theme['color-success-500']}
                      />
                    )
                  }
                  placeholder={
                    isFetchingOrderDates ? 'Loading...' : 'Select Hours'
                  }
                  disabled={lodashIsEmpty(selectedDate)}
                  onSelect={({ row }: any) => setSelectedHours(hours[row])}
                  value={_formatTime(selectedHours)}
                  size="large"
                >
                  {hours.map((data, i) => (
                    <SelectItem key={i} title={_formatTime(data)} />
                  ))}
                </Select>
              </Layout>
            </Layout>
          </Layout>
        </Collapsible>

        <Button
          disabled={isAsap ? false : lodashIsEmpty(selectedHours)}
          plain
          style={styles.proceedBtn}
          onPress={_onProceed}
        >
          <Text category="p2" status="basic">
            Proceed
          </Text>
        </Button>
      </Layout>
    </PopupModal>
  );
};

const styles = StyleSheet.create({
  container: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
  },
  layout: {
    width: '48%',
  },
  button: {
    ...ThemeStyle.flex1,
    backgroundColor: ThemeColor.gray,
    marginTop: 10,
  },
  enableBtn: {
    flex: 1,
    marginTop: 10,
    borderWidth: 1,
  },
  disableBtn: {
    backgroundColor: ThemeColor.gray,
    flex: 1,
    marginTop: 10,
    opacity: 0.5,
    elevation: 0,
  },
  buttonActive: {
    backgroundColor: ThemeColor.green,
  },
  innerBtn: {
    height: 50,
    minWidth: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  proceedBtn: {
    ...ThemeStyle.spacingTopMedium,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
});

export default SchedulePicker;
