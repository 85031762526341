import { useEffect } from 'react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashPick from 'lodash/pick';

const useQuantitySelector = ({
  isUpdate,
  mealPlan,
  onLimitReached,
  ...rest
}) => {
  const limitReachedProps = lodashPick(rest, [
    'productId',
    'storeId',
    'stocks',
    'onReschedule',
  ]);

  const selectorProps = lodashPick(rest, [
    'disabled',
    'loading',
    'value',
    'valueOnCart',
    'onChange',
  ]);

  const { stocks } = limitReachedProps;
  const { value, valueOnCart, onChange } = selectorProps;
  const hasStockLimit = stocks?.remainingStock !== -1;
  const isMealPlan = !lodashIsEmpty(mealPlan);
  const totalSelectorValue = isUpdate ? value : value + (valueOnCart || 0); // incase valueOnCart is undefined, make it 0 it just means no same item on cart yet
  // maxQty on add to cart, just use the remainingStock since its already consider the stocks reduce the item in the cart also item that is in the same category
  // maxQty on update cart, use the remainingStock + valueOnCart because remainngStock consider the cart item and item that is in same category
  const maxQty = isUpdate
    ? stocks?.remainingStock + valueOnCart
    : stocks?.remainingStock;
  const isMaxQty = hasStockLimit ? value >= maxQty : false;
  const isMealPlanMaxQty = value >= mealPlan?.limit;
  const isMaxReached = isMealPlan ? isMealPlanMaxQty : isMaxQty;

  const isMealPlanCannotAdd = mealPlan?.limit === 0; // if meal plan limit is 0, it means this item cannot be add to cart anymore
  const isNonMealPlanCannotAdd = stocks?.remainingStock === 0; // if not meal plan and remainingStock left is 0
  const displayZeroValue =
    !isUpdate && (isMealPlanCannotAdd || isNonMealPlanCannotAdd);

  if (onLimitReached) {
    useEffect(() => {
      if (!stocks?.isLoading) {
        onLimitReached?.(isMaxReached);
      }
    }, [stocks?.isLoading, isMaxReached]);
  }

  const _changeCounter = (changeCount) => () => onChange(value + changeCount);

  return {
    limitReachedProps,
    selectorProps,
    displayZeroValue,
    totalSelectorValue,
    maxQty,
    isMaxReached,
    stocks,
    qtyValueSelector: value,
    changeCounter: _changeCounter,
  };
};

export default useQuantitySelector;
