import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import lodashPick from 'lodash/pick';
import { UAParser } from 'ua-parser-js';

import QuantitySelector from '../../Components/QuantitySelector';
import useSchedulePicker from '../../Components/SchedulePicker/index.controller';
import CartHelper from '../../Helper/Cart';
import { allCartSelector } from '../../RTK/cart/selectors';
import { updateQuantity } from '../../RTK/product';
import { productSelector } from '../../RTK/product/selectors';
import { whenFilterSelector } from '../../RTK/filter/selectors';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';
import constants from '../../Config/constants';

import { specialInstructionDefaultValues } from './SpecialInstruction';

function ProductFooter({
  cartData,
  mealPlan,
  productData,
  stocks,
  dispatchUpdateQuantity,
  ...rest
}) {
  const { setRescheduleStoreId } = useSchedulePicker();
  const { device } = UAParser();

  const existingCartItem = CartHelper?.getCartItem(
    productData?.store?.id,
    productData?.id,
    productData?.extras || [],
    productData?.instructions || specialInstructionDefaultValues,
    cartData
  );
  const webProps = lodashPick(rest, [
    'totalPrice',
    'isAvailable',
    'disabled',
    'addToCartPress',
  ]);

  const _onQtyChange = (qty) => dispatchUpdateQuantity(qty);
  const isMobileWebView = constants.isWeb && device.type === 'mobile';
  return (
    <Fragment>
      <View style={ThemeStyle.sectionSeparator} />

      <View
        dataSet={{ media: !isMobileWebView && ids.wrapper }}
        style={!isMobileWebView && styles.wrapper}
      >
        <QuantitySelector
          // common props
          isUpdate={productData?.isUpdate}
          mealPlan={mealPlan}
          // limit reached props
          productId={productData?.id}
          storeId={productData?.store?.id}
          stocks={stocks}
          onReschedule={() =>
            constants.isWeb
              ? rest?.toggleDateTimePicker()
              : setRescheduleStoreId(productData.store.id)
          }
          // selector props
          value={productData?.quantity || 1}
          valueOnCart={existingCartItem?.quantity}
          onChange={_onQtyChange}
          {...webProps}
        />
      </View>
    </Fragment>
  );
}

const { ids, styles } = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.spacingBottomMedium,
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.spacingTop,
    },
  },
});

const mapStateToProps = (state) => ({
  cartData: allCartSelector(state),
  productData: productSelector(state),
  when: whenFilterSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateQuantity: (data) => dispatch(updateQuantity(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductFooter);
