import React, { useRef, useEffect, useState, useReducer } from 'react';
import { View, ScrollView } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Icon, useTheme } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import { RAZZLE_BUILD_MODE } from '@env';

import Button from '../../../Components/Button';
import SearchInput from '../../../Components/Search';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import BreakPoints from '../../../Theme/styles/breakpoints';

import { ScaleButtonAnimation } from '../../../Components/Web/Intereaction';
import ScreenSize from '../../../Helper/ScreenSize';

const isBranded = RAZZLE_BUILD_MODE === 'branded';

const TabBar = ({
  sections,
  renderTab,
  tabBarStyle,
  currentIndex,
  onPress,
  onPressSearch,
  onSearch,
}) => {
  const { sm } = ScreenSize();
  const store_theme = useTheme();

  const scrollViewRef = useRef(null);
  const searchRef = useRef(null);
  const menuTabContainerMeasurementsRef = useRef(null);
  const tabContainerMeasurementsRef = useRef(null);
  const searchContainerMeasurementsRef = useRef(null);
  const tabsMeasurementsRef = useRef({});

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [isReached, setisReached] = useState(false);
  const [nextOffset, setNextOffset] = useState(4);
  const [prevEnabled, setPrevEnabled] = useState(false);

  const categoryHighlight =
    isBranded && store_theme['category_header_text_color'];

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        x: getScrollAmount(),
        animated: true,
      });
    }
  }, [currentIndex, sm]);

  const getScrollAmount = () => {
    const position = currentIndex;
    const pageOffset = 0;
    if (position !== Object.keys(tabsMeasurementsRef.current).length - 1) {
      const containerWidth = menuTabContainerMeasurementsRef.current?.width; //WindowWidth;
      const tabWidth = tabsMeasurementsRef.current[position]?.width;
      const nextTabMeasurements = tabsMeasurementsRef.current[position + 1];
      const nextTabWidth =
        (nextTabMeasurements && nextTabMeasurements?.width) || 150;
      const tabOffset = tabsMeasurementsRef.current[position]?.left;
      const absolutePageOffset = pageOffset * tabWidth;
      let newScrollX = tabOffset + absolutePageOffset;

      newScrollX -=
        (containerWidth -
          (1 - pageOffset) * tabWidth -
          pageOffset * nextTabWidth) /
        2;
      newScrollX = newScrollX >= 0 ? newScrollX + nextTabWidth : 0;

      setNextOffset(position);

      return newScrollX;
    } else {
      return tabsMeasurementsRef.current[position]?.right || 0;
    }
  };

  const onTabContainerLayout = (e) => {
    tabContainerMeasurementsRef.current = e.nativeEvent.layout;
    forceUpdate();
  };

  const onMenuTabContainerLayout = (e) => {
    menuTabContainerMeasurementsRef.current = e.nativeEvent.layout;
  };
  const onSearchContainerLayout = (e) => {
    searchContainerMeasurementsRef.current = e.nativeEvent.layout;
  };
  const onTabLayout = (key) => (ev) => {
    const { x, width, height } = ev.nativeEvent.layout;
    tabsMeasurementsRef.current[key] = {
      left: x,
      right: x + width,
      width,
      height,
    };
  };

  const renderTabItem = (section, key) => {
    const isActive = currentIndex === key;
    let color_val = '';
    if (!lodashIsEmpty(categoryHighlight) && categoryHighlight !== '') {
      const val = categoryHighlight;
      color_val = val.length > 7 ? val.substring(0, val.length - 2) : val;
    }

    return (
      <ScaleButtonAnimation
        key={key}
        testID="category_btn"
        onLayout={onTabLayout(key)}
        onPress={() => {
          onPress(key);
        }}
        buttonStyle={({ hovered }) => [
          styles.mainTabButton,
          hovered &&
            (color_val !== ''
              ? {
                  borderRadius: 5,
                  backgroundColor: `${color_val}1A` || ThemeColor.green100,
                }
              : ThemeStyle.buttonHovered1),
          ,
        ]}
        contentStyle={[
          isActive
            ? {
                ...styles.sectionSelected,
                borderBottomColor: categoryHighlight || ThemeColor.green,
              }
            : styles.sectionUnselected,
        ]}
      >
        {renderTab({ isActive, ...section })}
      </ScaleButtonAnimation>
    );
  };

  const prevSlide = () => {
    const sum = nextOffset - 4;
    scrollViewRef.current.scrollTo({
      animated: true,
      x: tabsMeasurementsRef.current[sum]?.left,
      y: 0,
    });
    setNextOffset(sum);
  };

  const nextSlide = () => {
    if (!isReached) {
      const sum =
        nextOffset + 4 >= sections.length
          ? sections.length - 1
          : nextOffset + 4;
      scrollViewRef.current.scrollTo({
        animated: true,
        x: tabsMeasurementsRef.current[sum]?.right,
        y: 0,
      });
      setNextOffset(sum);
    }
  };

  const _isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingBottom = 20;

    setPrevEnabled(contentOffset.x > 0);
    setisReached(
      layoutMeasurement.width + contentOffset.x >=
        contentSize.width - paddingBottom
    );
  };

  return (
    <View style={[styles.tabWrapper, tabBarStyle]}>
      <View
        onLayout={onMenuTabContainerLayout}
        dataSet={{ media: ids.menuBtnContainer }}
        style={styles.menuBtnContainer}
      >
        <View
          dataSet={{ media: ids.searchContainer }}
          style={styles.searchContainer}
          onLayout={onSearchContainerLayout}
        >
          <View style={ThemeStyle.pageBackground}>
            <SearchInput
              ref={searchRef}
              placeholder="Search store menu"
              onSearch={onSearch}
              inputStyle={[ThemeStyle.pageBackground, styles.searchInput]}
              textStyle={styles.textInputStyle}
              storeTheme={store_theme}
            />
          </View>
        </View>
        <View
          dataSet={{ media: ids.searchButtonContainer }}
          style={styles.searchButtonContainer}
        >
          <Button onPress={onPressSearch} plain>
            <Icon
              name={'search'}
              fill={store_theme['icon_color'] || ThemeColor.darkGray}
              pack="ion"
              style={styles.searchIcon}
            />
          </Button>
        </View>

        {prevEnabled && !lodashIsEmpty(sections) && (
          <ScaleButtonAnimation
            testID="prev_btn"
            onPress={prevSlide}
            buttonStyle={styles.scaleControlButton}
            contentStyle={styles.controlButton}
            scale={1.2}
          >
            <Icon
              name={'chevron-back'}
              fill={store_theme['icon_color'] || ThemeColor.green}
              pack="ion"
              style={styles.controlIcon}
            />
          </ScaleButtonAnimation>
        )}
        {!lodashIsEmpty(sections) && (
          <ScrollView
            ref={scrollViewRef}
            style={{ marginLeft: 10 }}
            showsHorizontalScrollIndicator={false}
            horizontal
            scrollEventThrottle={1500}
            onScroll={({ nativeEvent }) => _isCloseToBottom(nativeEvent)}
          >
            <View
              onLayout={onTabContainerLayout}
              style={{ flexDirection: 'row' }}
            >
              {sections.map(renderTabItem)}
            </View>
          </ScrollView>
        )}
        {!isReached &&
          tabContainerMeasurementsRef.current?.width >
            menuTabContainerMeasurementsRef.current?.width -
              searchContainerMeasurementsRef.current?.width &&
          !lodashIsEmpty(sections) && (
            <ScaleButtonAnimation
              testID="next_btn"
              onPress={nextSlide}
              buttonStyle={[styles.scaleControlButton, { right: 10 }]}
              contentStyle={styles.controlButton}
              scale={1.2}
            >
              <Icon
                name={'chevron-forward'}
                fill={store_theme['icon_color'] || ThemeColor.green}
                pack="ion"
                style={styles.controlIcon}
              />
            </ScaleButtonAnimation>
          )}
      </View>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  tabWrapper: {
    backgroundColor: ThemeColor.white,
    borderBottomWidth: 0.8,
    borderBottomColor: ThemeColor.gray,
    height: 60,
  },
  searchInput: {
    borderColor: ThemeColor.gray,
    borderRadius: 30,
    borderWidth: 1,
  },
  menuBtnContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: 10,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingHorizontal: 10,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
    [`@media ${BreakPoints.xl}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
    [`@media ${BreakPoints.xxl}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
  },
  menuPickerBtn: {
    backgroundColor: ThemeColor.white,
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    borderBottomWidth: 0,
    borderBottomColor: null,
  },
  skeletonContainer: {
    marginTop: 10,
    display: 'flex',
    [`@media ${BreakPoints.xs}`]: {
      marginTop: 10,
    },
    [`@media ${BreakPoints.sm}`]: {
      marginTop: 10,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
    [`@media ${BreakPoints.xl}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
    [`@media ${BreakPoints.xxl}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
  },
  scaleControlButton: {
    borderWidth: 1,
    borderColor: ThemeColor.gray100,
    backgroundColor: ThemeColor.white,
    borderRadius: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  controlButton: {
    width: 30,
    height: 30,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlIcon: { width: 20, height: 20 },

  mainTabButton: {
    marginTop: 5,
    height: 50,
  },
  sectionSelected: {
    justifyContent: 'center',
    flexGrow: 1,
    marginHorizontal: 10,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 3,
  },
  sectionUnselected: {
    justifyContent: 'center',
    marginHorizontal: 10,
    flexGrow: 1,
    height: '100%',
    alignItems: 'center',
    borderBottomWidth: 0,
    borderBottomColor: null,
  },
  textInputStyle: {
    ...ThemeStyle.regular,
    color: ThemeColor.black,
    fontSize: 12,
  },
  searchContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    width: 'auto',
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },
  searchButtonContainer: {
    [`@media ${BreakPoints.xs}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },
  accessoryRightContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 10,
  },
  search_input: {
    borderColor: ThemeColor.gray,
    borderRadius: 30,
    borderWidth: 1,
  },
  searchIcon: { width: 20, height: 20 },
  icon_size: { width: 15, height: 15 },
  closeIcon: {
    width: 25,
    height: 25,
  },
});
export default TabBar;
