import { useDispatch, useSelector } from 'react-redux';

import constants from '../../Config/constants';
import messages from '../../Config/messages';

import DateHelper from '../../Helper/Date';

import useToast from '../../Hooks/useToast';
import { setRescheduleStoreId } from '../../RTK/utility';
import { shopSelector } from '../../RTK/shop/selectors';
import { updateCheckoutDetails } from '../../RTK/checkout';
import { checkout } from '../../RTK/defaultValues';
import dayjs from 'dayjs';
import { changeWhenFilter } from '../../RTK/filter';

const useSchedulePicker = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const storeId = useSelector((state) => shopSelector(state)?.id);
  const rescheduleStoreId = useSelector(
    (state: any) => state.utility.rescheduleStoreId // Fix typings of utility
  );

  const interval = constants.minuteInterval; // Example 8:00, 8:30, 9:00 etc

  const _rescheduleOnStorePage = (date: string) => {
    const dayjsObject = dayjs(date);
    const value = {
      label: dayjsObject.format(constants.DATE_DISPLAY_FORMAT),
      value: dayjsObject.toISOString(),
      date: dayjsObject.format(constants.DBDATE_FORMAT),
      time: dayjsObject.format(constants.DBTIME_FORMAT),
    };
    // set checkout selected schedule
    dispatch(
      updateCheckoutDetails({
        store_id: storeId,
        keyToUpdate: checkout.keys.DELIVERY_SCHEDULE,
        keyValue: value,
      })
    );
    toast.show(`${messages.CHANGE_SCHEDULE} ${value?.label || 'ASAP'}.`); // show toast to user
    dispatch(changeWhenFilter(value)); // update date filter on homepage
    _setRescheduleStoreId('');
  };

  const _setRescheduleStoreId = (storeId: string) => {
    dispatch(setRescheduleStoreId(storeId));
  };

  const _generateDateOptions = () => {
    const currentDate = dayjs();
    const options = [];
    const daysArray = [];
    const initial = dayjs().startOf('day');
    const todayDate = initial.format('MM/DD/YYYY');

    for (let i = 0; i <= 7; i++) {
      const nextDay = currentDate.add(i, 'day');
      const storeDate = nextDay.format('MM/DD/YYYY');
      let time_options = [];
      daysArray.push({
        text: nextDay.format('ddd, MMM DD'),
        value: nextDay.format(constants.DBDATE_FORMAT),
        opening: '01:00',
        closing: '23:30',
      });
      const isToday = todayDate === storeDate; // check if date is today

      // loop through time slots
      daysArray.forEach(({ opening, closing }) => {
        const current = dayjs().add(30, 'minute');
        const currentTime = current.format('HH:mm'); // 24 hours, to match DB opening time
        let [startHour, startMinutes] = opening.split(':'); // initial startHour is opening hour
        let [endHour] = closing.split(':'); // initial endHour is closing hour
        let canAsap = false;
        // for checking for start hour, use only current time if today and too late for opening time
        if (isToday && currentTime > opening) {
          // if today and current time is too late for opening time, use current time as start hour
          let currentHour = current.hour();
          let currentMinutes = current.minute();
          let minuteBeforeInterval = interval - currentMinutes;
          if (minuteBeforeInterval < 0) {
            currentHour += 1;
            currentMinutes = 0;
          } else {
            currentMinutes = 30;
          }
          startHour = currentHour;
          startMinutes = currentMinutes;
          canAsap = true;
        }
        // for generating date and time options
        const endTime = DateHelper.getDayjsAtTime(closing);
        let loopHr = Number(startHour);
        let loopMin = Number(startMinutes);

        while (loopHr <= endHour) {
          const endingTime = DateHelper.getDayjsAtTime(`${loopHr}:${loopMin}`);
          if (endingTime.isSameOrBefore(endTime)) {
            // if not exceeding store closing hour, generate date and time object
            const option = dayjs(`${storeDate} ${loopHr}:${loopMin}`);
            time_options.push({
              value: option.format('HH:mm'),
              start: option.toISOString(), // reference value
              end: dayjs(option, 'hh:mm').add(30, 'minutes').format(),
            });
          }
          // after inserting the time to options, increment the loopHr and loopMin
          loopMin += interval;
          if (loopMin >= 60) {
            // if more than 60 the minutes then +1 to hours and reset minutes to 0
            loopHr += 1;
            loopMin = 0;
          }
        }
      });
      options.push({
        value: nextDay.format(constants.DBDATE_FORMAT),
        iso: nextDay.format(),
        time_options: time_options,
      });
    }
    return options;
  };

  return {
    showReschedule: Boolean(rescheduleStoreId),
    rescheduleStoreId,
    rescheduleOnStorePage: _rescheduleOnStorePage,
    setRescheduleStoreId: _setRescheduleStoreId,
    generateDateOptions: _generateDateOptions,
  };
};

export default useSchedulePicker;
