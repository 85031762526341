import React from 'react';
import { ScrollView, View, StyleProp, ViewStyle } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Popup from 'reactjs-popup';
import { Layout, Text } from '@ui-kitten/components';
import { UAParser } from 'ua-parser-js';
import lodashIsUndefined from 'lodash/isUndefined';
import lodashIsEmpty from 'lodash/isEmpty';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle, { deviceWidth } from '../../../Theme/styles';
import BreakPoints from '../../../Theme/styles/breakpoints';

import CloseButton from '../CloseButton';

import './index.css';

type PopupModalProp = {
  isOpen: boolean;
  onClosed: () => void;
  onCloseButtonClick?: () => void;
  baseWidth: number | string;
  title: string;
  closeOnDocumentClick?: boolean;
  dataSet?: Record<string, string>;
  mainContentWrapperStyle?: StyleProp<ViewStyle>;
  childContentContainerStyle?: StyleProp<ViewStyle>;
  hasHeader?: boolean;
  hasHeaderLine?: boolean;
  hasFooterLine?: boolean;
  headerStyle?: StyleProp<ViewStyle>;
  footerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  scrollEnabled?: boolean;
};
const PopupModal = ({
  isOpen = false,
  onClosed,
  onCloseButtonClick,
  baseWidth,
  title = '',
  closeOnDocumentClick = false,
  dataSet,
  mainContentWrapperStyle,
  childContentContainerStyle,
  hasHeader = true,
  hasHeaderLine,
  hasFooterLine = false,
  headerStyle,
  footerStyle,
  children,
  footer,
  scrollEnabled = true,
}: PopupModalProp) => {
  const { device } = UAParser();
  const contentStyle = {
    background: 'transparent',
  };
  const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
  const arrowStyle = { color: '#000' }; // style for an svg element
  const ChildrenWrapper = scrollEnabled ? ScrollView : View;
  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick={closeOnDocumentClick}
      closeOnEscape={false}
      onClose={(e) => {
        e?.preventDefault?.();
        onClosed?.();
      }}
      modal
      nested
      contentStyle={contentStyle}
      overlayStyle={overlayStyle}
      arrowStyle={arrowStyle}
    >
      {
        //@ts-ignore
        (close) => {
          return (
            <View
              dataSet={
                device.type !== 'mobile' && {
                  media: Boolean(dataSet?.media)
                    ? dataSet?.media
                    : ids.mainContentWrapper,
                }
              }
              style={[
                { width: Boolean(baseWidth) ? baseWidth : 550 },
                device.type !== 'mobile'
                  ? styles.mainContentWrapper
                  : styles.wrapperMobile,
                mainContentWrapperStyle,
              ]}
            >
              {hasHeader && (
                <View
                  style={[
                    hasHeaderLine
                      ? styles.headerContainer2
                      : styles.headerContainer,
                    headerStyle,
                  ]}
                >
                  {!lodashIsEmpty(title) && typeof title === 'string' ? (
                    <Layout level="1">
                      <Text category="s2">{title}</Text>
                    </Layout>
                  ) : (
                    title
                  )}
                  <View
                    style={
                      hasHeaderLine
                        ? styles.closeButtonContainer2
                        : styles.closeButtonContainer
                    }
                  >
                    <CloseButton onPress={onCloseButtonClick || close} />
                  </View>
                </View>
              )}
              <ChildrenWrapper
                style={[styles.contentContainer, childContentContainerStyle]}
              >
                {children}
              </ChildrenWrapper>

              {!lodashIsUndefined(footer) && (
                <View
                  style={[
                    styles.contentFooterContainer,
                    hasFooterLine && styles.hasFooterLine,
                    footerStyle,
                  ]}
                >
                  {footer}
                </View>
              )}
            </View>
          );
        }
      }
    </Popup>
  );
};

const { ids, styles } = StyleSheet.create({
  mainContentWrapper: {
    alignSelf: 'center',
    backgroundColor: ThemeColor.white,
    borderRadius: 10,
    overflow: 'hidden',
    maxHeight: '80vh',
    [`@media ${BreakPoints.xs}`]: {
      width: '95vw',
    },
  },
  wrapperMobile: {
    width: '95vw',
    overflow: 'hidden',
    padding: 5,
    alignSelf: 'center',
    backgroundColor: ThemeColor.white,
    borderRadius: 10,
  },
  contentContainer: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.spacingBottomMedium,
  },
  contentFooterContainer: { ...ThemeStyle.spacingBottomMedium },
  headerContainer: {
    flex: 1,
    minHeight: 50,
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.spacingBottomSmall,
  },
  headerContainer2: {
    flex: 1,
    minHeight: 50,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: ThemeColor.lightGray,
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.spacingBottomMedium,
  },
  hasFooterLine: {
    borderTopColor: ThemeColor.lightGray,
    borderTopWidth: 1,
  },
  closeButtonContainer: {
    width: '100%',
    position: 'absolute',
    alignItems: 'flex-end',
  },
  closeButtonContainer2: {
    width: '100%',
    position: 'absolute',
    alignItems: 'flex-end',
    paddingRight: 15,
  },
});
export default PopupModal;
