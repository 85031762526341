import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import FloatingButtonBottom from '../../Components/FloatingButtonBottom';
import FloatingContentProductCart from '../../Components/FloatingButtonBottom/ProductCart';
import useMealPlan from '../../Hooks/useMealPlan';
import withController from '../../Hooks/withController';
import withProductController from './controller/withProductController';
import { globalSpacing } from '../../Theme/styles';

import ExtraGroup from './ExtraGroup';
import Loader from './Loader';
import ProductFooter from './ProductFooter';
import ProductHeading from './ProductHeading';

class Product extends React.Component {
  _renderListItem = (item, index) => {
    const { controller } = this.props;
    let defaultSelected = controller.state.isUpdate
      ? controller.getSelectedExtra(item)
      : null;

    return (
      <ExtraGroup
        key={index}
        defaultSelected={defaultSelected}
        group={{ ...item, index }}
        onGroupSelection={controller.addOrRemoveExtras}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          controller.state.extrasCoords[index] = layout.y;
        }}
      />
    );
  };

  _renderListHeader = () => {
    const { controller, setParams } = this.props;
    return (
      <ProductHeading
        noStock={controller.state.stocks.stock === 0}
        productData={this.props.productData}
        thisProductOnCart={
          controller.state.isUpdate ? null : controller.state.thisProductOnCart
        }
        setParams={setParams}
      />
    );
  };

  _renderListFooter = () => {
    const { controller } = this.props;
    return (
      <ProductFooter
        stocks={controller.state.stocks}
        {...controller.getMealPlanProps()}
      />
    );
  };

  _onScrollToIndexFailed = () => {}; // this function is fix for error happen when testing

  render() {
    const {
      controller,
      isAddingOrUpdatingCart,
      productData,
      productLoading,
      scrollHandlers,
    } = this.props;
    const mealPlanProps = controller.getMealPlanProps();
    const isMealPlanCannotAdd = mealPlanProps?.mealPlan?.limit === 0; // if meal plan limit is 0, it means this item cannot be add to cart anymore

    if (productLoading) {
      return <Loader />;
    }

    return (
      <Fragment>
        <ScrollView
          bounces={false}
          style={styles.scrollWrapper}
          contentContainerStyle={styles.scrollContainer}
          showsVerticalScrollIndicator={false}
          {...scrollHandlers}
        >
          {/* Heading */}
          {this._renderListHeader()}

          {/* Extras */}
          {productData.extra_group.map(this._renderListItem)}

          {/* Footer */}
          {this._renderListFooter()}
        </ScrollView>

        {/* Add/Update to cart button */}
        <FloatingButtonBottom
          testID="addOrUpdateCartButton"
          visible={true}
          onPress={() =>
            controller.onAddToCartPressed(scrollHandlers.ref.current)
          }
          disabled={controller.state.disableAddOrUpdateButton}
          plain
        >
          <FloatingContentProductCart
            isUpdate={controller.state.isUpdate}
            submitting={controller.state.submitting}
            comparePrice={controller.getItemOriginalPrice()}
            price={controller.getItemTotalPrice()}
          />
        </FloatingButtonBottom>
      </Fragment>
    );
  }
}

Product = withProductController(Product);
Product = withController(Product, useMealPlan, 'mealPlanData');

const styles = StyleSheet.create({
  scrollWrapper: {
    overflow: 'visible',
    top: 0,
  },
  scrollContainer: {
    marginTop: -21, // draggable area size
    paddingBottom: globalSpacing,
  },
});

const mapStateToProps = (state) => ({
  isAddingOrUpdatingCart: state.cart.isAddingOrUpdatingCart,
  productData: state.product.data,
  productLoading: state.product.loading,
  user: state.user.user,
  whenFilter: state.filter.home.when,
});

export default connect(mapStateToProps)(Product);
