import { RAZZLE_BUILD_MODE } from '@env';

import { baseApi } from '../baseApi';

import { OrderDates } from './types';

const isBranded = RAZZLE_BUILD_MODE === 'branded';
export const storeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrderDates: builder.query<OrderDates, { payload: string }>({
      query: (params) => ({
        url: isBranded
          ? `/branded-website/stores/${params.payload}/date-options?v=2`
          : `/store/${params.payload}/order-dates`,
        method: 'GET',
        headers: { ...(!isBranded && { 'x-api-version': '1' }) },
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetOrderDatesQuery } = storeApi;
