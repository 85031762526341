import React, { Fragment } from 'react';
import { View } from 'react-native';
import { Divider } from '@ui-kitten/components';

import { CircleLoader, RectangleLoader } from '../../Components/Loader';
import constants from '../../Config/constants';
import ThemeStyle, { deviceHeight } from '../../Theme/styles';

class Loader extends React.PureComponent {
  render() {
    return (
      <View
        style={[
          ThemeStyle.flex1,
          ThemeStyle.pageBackground,
          { marginTop: -21 },
        ]}
      >
        <View style={ThemeStyle.spacingBottomMedium}>
          <RectangleLoader width="100%" height={deviceHeight * 0.35} />
        </View>

        <View
          style={[
            ThemeStyle.pageHorizontalSpacing,
            ThemeStyle.spacingBottomMedium,
          ]}
        >
          <View style={ThemeStyle.spacingBottomMedium}>
            {/* Product name */}
            <RectangleLoader width={200} height={40} />
          </View>

          {/* Product description */}
          <RectangleLoader width="100%" height={18} />
        </View>

        <Divider style={ThemeStyle.sectionSeparator} />

        {/* extra group 1 */}
        <View
          style={[
            ThemeStyle.pageVerticalSpacingMedium,
            ThemeStyle.pageHorizontalSpacing,
          ]}
        >
          <View style={ThemeStyle.spacingBottomSmall}>
            {/* Extra group name */}
            <RectangleLoader width={150} height={25} />
          </View>

          <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
            <RectangleLoader width={100} height={18} />
            <RectangleLoader width={80} height={18} />
          </View>

          {/* extra group data 1 */}
          <View>
            <View
              style={[ThemeStyle.spacingTop, ThemeStyle.spacingBottomMedium]}
            >
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <View style={ThemeStyle.spacingRightMedium}>
                  {/* Extra group radio button */}
                  <CircleLoader size={30} />
                </View>

                <View style={ThemeStyle.flex1}>
                  <View style={ThemeStyle.spacingBottomSmall}>
                    {/* Extra group name */}
                    <RectangleLoader width={150} height={16} />
                  </View>
                  {/* Extra group price */}
                  <RectangleLoader width={50} height={16} />
                </View>
              </View>
            </View>

            <Divider style={ThemeStyle.indentedDivider} />
          </View>

          {/* extra group data 2 */}
          <View>
            <View
              style={[
                ThemeStyle.spacingTopMedium,
                ThemeStyle.spacingBottomMedium,
              ]}
            >
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <View style={ThemeStyle.spacingRightMedium}>
                  {/* Extra group radio button */}
                  <CircleLoader size={30} />
                </View>

                <View style={ThemeStyle.flex1}>
                  <View style={ThemeStyle.spacingBottomSmall}>
                    {/* Extra group name */}
                    <RectangleLoader width={150} height={16} />
                  </View>
                  {/* Extra group price */}
                  <RectangleLoader width={50} height={16} />
                </View>
              </View>
            </View>

            <Divider style={ThemeStyle.indentedDivider} />
          </View>

          {/* extra group data 3 */}
          <View>
            <View
              style={[
                ThemeStyle.spacingTopMedium,
                ThemeStyle.spacingBottomSmall,
              ]}
            >
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <View style={ThemeStyle.spacingRightMedium}>
                  {/* Extra group radio button */}
                  <CircleLoader size={30} />
                </View>

                <View style={ThemeStyle.flex1}>
                  <View style={ThemeStyle.spacingBottomSmall}>
                    {/* Extra group name */}
                    <RectangleLoader width={150} height={16} />
                  </View>
                  {/* Extra group price */}
                  <RectangleLoader width={50} height={16} />
                </View>
              </View>
            </View>
          </View>
        </View>

        <Divider style={ThemeStyle.sectionSeparator} />

        {/* extra group 2 */}
        <View
          style={[
            ThemeStyle.pageVerticalSpacingMedium,
            ThemeStyle.pageHorizontalSpacing,
          ]}
        >
          <View style={ThemeStyle.spacingBottomSmall}>
            {/* Extra group name */}
            <RectangleLoader width={150} height={25} />
          </View>

          <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
            <RectangleLoader width={100} height={18} />
            <RectangleLoader width={80} height={18} />
          </View>

          {/* extra group data 1 */}
          <View>
            <View
              style={[ThemeStyle.spacingTop, ThemeStyle.spacingBottomMedium]}
            >
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <View style={ThemeStyle.spacingRightMedium}>
                  {/* Extra group checkbox */}
                  <RectangleLoader width={25} height={25} />
                </View>

                <View style={ThemeStyle.flex1}>
                  <View style={ThemeStyle.spacingBottomSmall}>
                    {/* Extra group name */}
                    <RectangleLoader width={150} height={16} />
                  </View>
                  {/* Extra group price */}
                  <RectangleLoader width={50} height={16} />
                </View>
              </View>
            </View>

            <Divider style={ThemeStyle.indentedDivider} />
          </View>

          {/* extra group data 2 */}
          <View>
            <View
              style={[
                ThemeStyle.spacingTopMedium,
                ThemeStyle.spacingBottomMedium,
              ]}
            >
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <View style={ThemeStyle.spacingRightMedium}>
                  {/* Extra group checkbox */}
                  <RectangleLoader width={25} height={25} />
                </View>

                <View style={ThemeStyle.flex1}>
                  <View style={ThemeStyle.spacingBottomSmall}>
                    {/* Extra group name */}
                    <RectangleLoader width={150} height={16} />
                  </View>
                  {/* Extra group price */}
                  <RectangleLoader width={50} height={16} />
                </View>
              </View>
            </View>

            <Divider style={ThemeStyle.indentedDivider} />
          </View>

          {/* extra group data 3 */}
          <View>
            <View
              style={[
                ThemeStyle.spacingTopMedium,
                ThemeStyle.spacingBottomSmall,
              ]}
            >
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <View style={ThemeStyle.spacingRightMedium}>
                  {/* Extra group checkbox */}
                  <RectangleLoader width={25} height={25} />
                </View>

                <View style={ThemeStyle.flex1}>
                  <View style={ThemeStyle.spacingBottomSmall}>
                    {/* Extra group name */}
                    <RectangleLoader width={150} height={16} />
                  </View>
                  {/* Extra group price */}
                  <RectangleLoader width={50} height={16} />
                </View>
              </View>
            </View>
          </View>
        </View>

        {!constants.isWeb && (
          <Fragment>
            <Divider style={ThemeStyle.sectionSeparator} />

            <View
              style={[
                ThemeStyle.pageVerticalSpacingMedium,
                ThemeStyle.pageHorizontalSpacing,
                ThemeStyle.alignItemsCenter,
              ]}
            >
              <View style={ThemeStyle.spacingBottomMedium}>
                {/* Special instructions button */}
                <RectangleLoader width={195} height={30} />
              </View>

              <View style={ThemeStyle.flexDirectionRowCenter}>
                {/* Quantity selector negative */}
                <CircleLoader size={50} />

                <View style={ThemeStyle.pageHorizontalSpacing}>
                  {/* Quantity selector value */}
                  <RectangleLoader width={15} height={30} />
                </View>

                {/* Quantity selector positive */}
                <CircleLoader size={50} />
              </View>
            </View>

            <View style={ThemeStyle.spacingTop} />
          </Fragment>
        )}
      </View>
    );
  }
}

export default Loader;
